<template>
  <div v-if="user">
    <div style="margin-bottom: 5rem">
      <h1 class="heading">
        <span style="cursor: pointer" @click="navigateToHome()"
          >Workers database</span
        >
        <span v-if="user.name"> / {{ user.name }}</span>
      </h1>
      <p style="font-size: 1.8rem">Here you can edit workers profile</p>
    </div>

    <div class="user">
      <div class="user__column">
        <div class="user__row">
          <div class="user__image">
            <img
              v-if="user.picture !== 'null' || this.tempPicture"
              :src="this.tempPicture ? this.tempPicture : user.picture"
              width="170"
              height="193"
            />
            <div
              v-if="user.picture !== 'null' && !this.viewUser"
              class="user__removeImage"
              @click="removeImage"
            >
              Remove
            </div>
          </div>

          <form
            v-show="!this.viewUser"
            class="user__field"
            enctype="multipart/form-data"
          >
            <span>Picture</span>
            <label for="pictureUpload" class="as-input as-fileUpload">
              Browse...
            </label>
            <input
              id="pictureUpload"
              :readonly="this.viewUser"
              type="file"
              name="picture"
              style="display: none"
              @change="handleFileUpload($event)"
            />
          </form>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Rating</span>
            <fieldset class="user__rating" :class="{ readOnly: this.viewUser }">
              <template v-for="(rating, index) in 5">
                <input
                  :key="rating"
                  type="radio"
                  :id="getStarIndex(rating)"
                  @change="user.rating = rating"
                  :checked="user.rating == rating"
                  name="rating"
                  value="rating"
                />
                <label
                  :key="rating + 's' + index"
                  :for="getStarIndex(rating)"
                ></label>
              </template>
            </fieldset>
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Comments</span>
            <textarea
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.comments"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="user__column">
        <div class="user__row">
          <div class="user__field">
            <span>Full name</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.name"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>City</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.city"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Personal code</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.personal_code"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Address</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.address"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Phone number</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.phone"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Driver licence category</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.driver_license"
            />
          </div>
          <div class="user__field">
            <span>Size of the clothes</span>
            <select
              :disabled="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.size"
            >
              <option value="XS">XS</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
              <option value="XXXL">XXXL</option>
            </select>
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Email</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.email"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Bank account number</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.bank_account"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Position</span>
            <select
              :disabled="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.position"
            >
              <option value="osition 1">Position 1</option>
              <option value="osition 2">Position 2</option>
              <option value="osition 3">Position 3</option>
              <option value="osition 4">Position 4</option>
              <option value="osition 5">Position 5</option>
              <option value="osition 6">Position 6</option>
              <option value="osition 7">Position 7</option>
            </select>
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Busy</span>
            <select
              :disabled="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.busy"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Years of Experience</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.experience"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Project</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.project"
            />
          </div>
        </div>
        <h2 class="user__title">Closest relative person</h2>
        <div class="user__row">
          <div class="user__field">
            <span>Full name</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.relative_name"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Address</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.relative_address"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Phone number</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.relative_phone"
            />
          </div>
        </div>
        <div class="user__row">
          <div class="user__field">
            <span>Email</span>
            <input
              type="text"
              :readonly="this.viewUser"
              :class="{ readOnly: this.viewUser }"
              v-model="user.relative_email"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="userActions">
      <div
        v-if="userFound"
        class="userActions__btn is-remove"
        @click="showConfirmationPopup(user)"
      >
        Remove
        <img src="@/assets/icons/pluss.svg" />
      </div>
      <div
        v-if="viewUser"
        @click="viewUser && editCurrentUser()"
        class="userActions__btn is-save"
      >
        Edit
      </div>
      <div
        v-if="!viewUser"
        @click="editUser ? updateCurrentUser() : addNewUser()"
        class="userActions__btn is-save"
      >
        Save
      </div>
    </div>
    <Popup v-if="showPopup">
      <h2>Are you sure?</h2>
      <p>You are trying to delete {{ activeUser && activeUser.name }}</p>
      <div class="popup__btnWrapper">
        <div
          class="userActions__btn is-remove"
          @click="showHideDeleteConfirmPopup(false)"
        >
          Cancel
        </div>
        <div class="userActions__btn is-save" @click="removeUser()">Delete</div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { getUsers, uploadFile, addUser, updateUser } from "@/api/api";
import { mapActions, mapMutations, mapState } from "vuex";
import Popup from "@/components/Popup";

const server_location = "/server/uploads/";

export default {
  data() {
    return {
      user: null,
      userFound: null,
      userImage: null,
      formImage: null,
      tempPicture: null,
      viewUser: false,
      editUser: false,
    };
  },
  computed: {
    ...mapState(["showPopup", "activeUser"]),
  },
  mounted() {
    const id = this.$route.params && this.$route.params.id;
    this.tempPicture = null;
    if (id) {
      this.viewUser = this.$route.path.indexOf("view") > -1;
      this.editUser = this.$route.path.indexOf("edit") > -1;
      if (this.$store.state.users.length === 0) {
        this.$store.commit("isLoading", true);
        getUsers().then((users) => {
          this.$store.commit("isLoading", false);
          if (users) {
            this.setUsers(users.data);
            this.getCurrentUser(id);
            this.userFound = true;
          } else {
            this.userFound = false;
          }
        });
      } else {
        this.getCurrentUser(id);
      }
    } else {
      this.user = [];
      this.userFound = false;
    }
  },
  methods: {
    ...mapMutations([
      "setUsers",
      "changeUserRating",
      "showHideDeleteConfirmPopup",
      "showConfirmationPopup",
    ]),
    ...mapActions(["deleteCurrentUser"]),
    updateUser(name, value) {
      this.user[name] = value;
    },
    removeUser() {
      this.deleteCurrentUser().then((response) => {
        if (response.status == 200) {
          this.$router.push("/");
        }
      });
    },
    navigateToHome() {
      this.$router.push({ path: "/" });
    },
    getStarIndex(number) {
      return "star" + (6 - number);
    },
    removeImage() {
      this.user.picture = "null";
      this.tempPicture = null;
    },
    getCurrentUser(id) {
      let user = this.$store.state.users.filter((user) => {
        return user.id == id;
      })[0];

      this.user = Object.assign({}, user);

      if (!this.user) {
        this.$router.push("/addUser");
      } else {
        this.userFound = true;
      }
    },
    editCurrentUser() {
      this.$router.push({ path: `/edit/${this.user.id}` });
    },
    async updateCurrentUser() {
      if (this.formImage) {
        this.$store.commit("isLoading", true);
        await uploadFile(this.formImage).then((fileName) => {
          this.$store.commit("isLoading", false);
          this.user = Object.assign({}, this.user, {
            picture: fileName.data ? server_location + fileName.data : null,
          });
        });
      }
      await updateUser(this.user).then((response) => {
        if (response.status == 200) {
          this.$router.push("/");
        }
      });
    },
    async addNewUser() {
      this.$store.commit("isLoading", true);
      await uploadFile(this.formImage).then((fileName) => {
        this.user = Object.assign({}, this.user, {
          picture: fileName.data ? server_location + fileName.data : null,
        });
        addUser(this.user).then((response) => {
          this.$store.commit("isLoading", false);
          if (response.status == 200) {
            this.$router.push("/");
          }
        });
      });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];

      let formData = new FormData();
      formData.append("file", file);
      this.formImage = formData; // will send to api

      this.tempPicture = URL.createObjectURL(formData.get("file"));
      this.user.picture = null;

      //cal api
    },
  },
  components: {
    Popup,
  },
};
</script>

<style lang="less" scoped>
.heading {
  font-size: 3.6rem;

  > span {
    font-weight: bold;
    &:first-child {
      color: #ff7d08;
    }
  }
}

.user {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
  }

  &__removeImage {
    margin-left: 1rem;
    margin-bottom: 1rem;
    font-size: 1.6rem;
    color: #ff7d08;
    font-weight: bold;
    cursor: pointer;
  }

  &__image {
    display: flex;
    align-items: flex-end;
  }

  &__rating {
    display: flex;
    cursor: pointer;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border: 0;
    padding-left: 0;
    margin-left: 0;

    &.readOnly {
      pointer-events: none;
    }

    label {
      cursor: pointer;
    }

    input:checked + label:hover,
    input:checked + label:hover ~ label,
    input:checked ~ label:hover,
    input:checked ~ label:hover ~ label,
    label:hover ~ input:checked ~ label {
      background-image: url("~@/assets/icons/star_filled.svg");
    }

    input:checked ~ label {
      background-image: url("~@/assets/icons/star_filled.svg");
    }

    &:not(:checked) {
      > label {
        background-image: url("~@/assets/icons/star.svg");
        width: 3.3rem;
        height: 3.5rem;
        display: inline-block;

        &:hover,
        &:hover ~ label {
          background-image: url("~@/assets/icons/star_filled.svg");
        }
      }
    }

    input {
      display: none;
    }
  }

  &__title {
    width: 100%;
    display: block;
    color: #141c25;
    text-align: left;
    font-size: 1.8rem;
    padding: 0 10px;
    margin-top: 4rem;
  }

  &__column {
    flex-grow: 1;
    margin-bottom: 2rem;

    @media screen and (min-width: 1240px) {
      width: 33%;
      width: 66%;
      margin-bottom: 0;
    }

    &:first-child {
      padding-right: 10px;

      > .user__row:first-child {
        margin-top: 0;
      }

      @media screen and (min-width: 1240px) {
        width: 33%;
      }

      > .user__row {
        flex-direction: column;
      }
    }

    &:last-child {
      padding-right: 0px;
      display: flex;
      flex-wrap: wrap;
      margin-top: -1.5rem;

      @media screen and (max-width: 1250px) {
        flex-direction: column;
      }

      .user__row {
        @media screen and (min-width: 1240px) {
          width: 50%;
          padding: 0 10px;
        }
      }
    }
    .user__row {
      margin-top: 1.5rem;
    }
  }

  &__row {
    display: flex;

    img {
      margin-bottom: 1rem;
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;

    + .user__field {
      padding-left: 2rem;
    }
    > span {
      margin-bottom: 0.5rem;
      font-size: 1.6rem;
      font-weight: 300;
    }
  }
}
</style>
